@import './../../sass/variables';

.wrapper {
  display: inline;
  vertical-align: middle;
}

.noEvent {
  pointer-events: none;
}

.iconWrapper {
  position: relative;
  display: inline-flex;
  align-items: center;
  transform: translateY(1px);
}

.textForHeight {
  width: 0;
  color: transparent;
  opacity: 0;
}

.before {
  &.xs {
    margin-right: 5px;
  }

  &.sm {
    margin-right: 8px;
  }

  &.md {
    margin-right: 10px;
  }

  &.lg {
    margin-right: 15px;
  }

  &.xl {
    margin-right: 20px;
  }
}

.after {
  order: 2;

  &.xs {
    margin-left: 5px;
  }

  &.sm {
    margin-left: 8px;
  }

  &.md {
    margin-left: 10px;
  }

  &.lg {
    margin-left: 15px;
  }

  &.xl {
    margin-left: 20px;
  }
}

@include media-breakpoint-up(sm) {
  .wrapper {
    display: flex;
    align-items: center;
  }
}
